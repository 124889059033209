@import "../../../styles/index.scss";
@import "../../../../node_modules/swiper/swiper.scss";

.gallery-modal {
  &__overlay {
    background: transparent;
    padding: 0;
    transition: all 1000ms ease;

    @media (min-width: 1024px) {
      background: rgba($title-color, 0.25);
    }

    &.entered {
      background: rgba($title-color, 0.25);
    }
  }

  &--open {
    > #root {
      filter: blur(5px);
      transition: all 1000ms ease;
    }
  }

  &__container {
    width: 100%;
    max-height: 90%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px;
    margin-bottom: 0;
    overflow: scroll;
    box-shadow: 0px -10px 30px 5px rgba($title-color, 0.25);
    overflow: hidden;

    @media (min-width: 1024px) {
      margin-bottom: auto;
      border-radius: 2px;
      width: 675px;
    }
  }

  &--enter {
    transform: translateY(100%);

    @media (min-width: 1024px) {
      transform: none;
      opacity: 0;
    }
  }

  &--enter-active {
    transform: translateY(0);
    transition: transform 300ms ease;

    @media (min-width: 1024px) {
      transform: none;
      opacity: 1;
      transition: opacity 300ms ease;
    }
  }

  &--exit {
    transform: translateY(0);

    @media (min-width: 1024px) {
      transform: none;
      opacity: 1;
    }
  }

  &--exit-active {
    transform: translateY(100%);
    transition: transform 300ms ease;

    @media (min-width: 1024px) {
      transform: none;
      opacity: 0;
      transition: opacity 300ms ease;
    }
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    &--button {
      padding: 15px;
      margin: -15px;
    }
  }

  &__photos {
    & &__top {
      margin: 10px auto;
      overflow: hidden;

      @media (min-width: 1024px) {
        width: 451px;
      }
    }

    & &__thumbs {
      height: 68px;

      @media (min-width: 1024px) {
        height: 100px;
        overflow: hidden;
        margin: 0 auto;
        width: 451px;

        & > .swiper-wrapper {
          align-items: center;
        }
      }
    }

    & &__title {
      text-align: center;
      font-size: 22px;
      color: $title-color;
      font-family: $font-cormorant;
      font-style: italic;
      font-weight: 600;
    }

    & &--image {
      height: calc(100vh * 0.5);
      background-size: cover;
      width: 100%;
      background-position: center;
    }

    & &--thumb {
      height: 65px;
      width: 65px;
      background-size: cover;
      background-position: center;
      margin: 0 3px;
      opacity: 0.5;

      &.swiper-slide-thumb-active {
        opacity: 1;
        transition: all 0.3s ease;
      }

      @media (min-width: 1024px) {
        height: 78px;
        width: 78px;
        margin: 0 5px;
        

        &.swiper-slide-thumb-active {
          opacity: 1;
          transform: scale3d(1.1, 1.1, 1.1);
          transition: all 0.3s ease;
        }
      }

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &--prev {
    position: absolute;
    bottom: 60px;
    left: 30px;
    visibility: hidden;

    @media (min-width: 1024px) {
      visibility: visible;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &--next {
    position: absolute;
    bottom: 60px;
    right: 30px;
    visibility: hidden;

    @media (min-width: 1024px) {
      visibility: visible;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
