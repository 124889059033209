@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond:400i,600i&display=swap');

@font-face {
    font-family: 'Optima';
    src: '../assets/fonts/Optima-Regular.woff2' format('woff2'), '../assets/fonts/Optima-Regular.woff' format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Optima';
    src: '../assets/fonts/Optima-Bold.woff2' format('woff2'), '../assets/fonts/Optima-Bold.woff' format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Optima';
    src: '../assets/fonts/Optima-Italic.woff2' format('woff2'), '../assets/fonts/Optima-Italic.woff' format('woff');
    font-weight: normal;
    font-style: italic;
}