@import "../../../styles/index.scss";

.header-height {
  height: auto;
}

@media (max-width: 640px) {
  .header-height {
    height: 30vh;
  }
}

.header {
  padding-top: 15px;
  height: 80px;

  &.header-product {
    @media (min-width: 1024px) {
      max-width: calc(100% / 3 * 2);
      margin-left: 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    &:last-child {
      justify-content: flex-end;
    }
  }

  &__button {
    &:hover {
      cursor: pointer;
    }
  }

  &__logo {
    justify-content: center;
  }
}

.disabledBtn {
  background-color: rgba(100, 116, 139, 1) !important;
}