// Fonts
$font-cormorant: 'Cormorant Garamond', Arial, sans-serif;
$font-optima: 'Optima', sans-serif;

// Colors
$main-color: #d3e4e4;
$secondary-color: #dba7ae;
$title-color: #183e3f;
$product-menu-color: #BDCDCD;
$product-selected-color: #D7E4E4;

$input-borders: #BDCDCD;
$input-text-color: #183E3F;
$sub-title-color: #37584F;

$input-morse-code: rgba(#183E3F, 0.3);

$breakpoint-small: 480px;
$breakpoint-medium: 760px;
