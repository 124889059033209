@import "../../../styles/index.scss";

.product {
  margin-bottom: 20px;
  transition: all 300ms ease;

  &:hover {
    transform: translateY(-5px);
    transition: all 300ms ease;
  }

  &__col {
    &:nth-child(2n - 1) {
      padding-left: 0;
      padding-right: 5px;
    }

    &:nth-child(2n) {
      padding-right: 0;
      padding-left: 5px;
    }

    @media (min-width: 768px) {
      &:nth-child(2n - 1) {
        padding-left: 15px;
        padding-right: 15px;
      }
  
      &:nth-child(2n) {
        padding-left: 15px;
        padding-right: 15px;
      }
      
  
      &:nth-child(3n - 1) {
        margin-top: 0px;
      }

    }

  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  &__image {
    @include aspect-ratio(1, 1);
    background-color: white;
    box-shadow: 0 0 54px 0 rgba(black, 0.08);
    overflow: hidden;

    &--selected {
      position: relative;

      > .product__image--content {
        filter: blur(6px);
      }

      &::after {
        animation: fadeIn 200ms;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background-color: rgba($product-selected-color, 0.5);
        z-index: 999;
      }
    }

    &--content {
      max-width: 100%;
      background-size: cover;
      background-position: center;
    }
  }

  &__cta {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    > span {
      color: $title-color;
      font-family: $font-cormorant;
      font-style: italic;
      font-weight: 600;
      font-size: 30px;
    }

    &--selected {
      opacity: 1;
      transition: opacity 200ms linear;
    }
  }

  &__title {
    padding: 0 20px;
    margin-top: 15px;
    font-family: $font-optima;
    font-size: 14px;
    line-height: 17px;
    color: $title-color;
  }

  &__subtitle {
    padding: 5px 20px;
    font-family: $font-optima;
    font-size: 12px;
    line-height: 14px;
    color: rgba($title-color, 0.6);
  }
}
