.h-textalign-left {
  text-align: left;
}

.h-textalign-center {
  text-align: center;
}

.h-width-100 {
  width: 100%;
}

.h-margintop {
  margin-top: 20px;
}

.h-display-none {
  display: none !important;
}

.h-display-flex {
  display: flex;
}

.h-justifycontent-spacebetween {
  justify-content: space-between;
}

.h-alignitems-center {
  align-items: center;
}

.h-alignitems-flexstart {
  align-items: flex-start;
}

.h-alignself-flexstart {
  align-self: flex-start;
}

.h-flexdirection-column {
  flex-direction: column;
}
