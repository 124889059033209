@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (max-width: 600px) {
  .cbe-canvas {
    width: 300px;
    height: 600px;
  }
}

/* Styles for desktop devices with screen width over 600px */
@media screen and (min-width: 601px) {
  .cbe-canvas {
    width: 800px;
    height: 800px;
  }
}

@font-face {
  font-family: "Optima nova";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  // src: url("./optima.woff") format("woff2");
}

.mobile-setting-menu {
  transform: translateY(100%);
  height: 50vh;
  overflow-y: scroll;
  z-index: 100;
  transition: transform 300ms ease-in-out;
  background-color: white;
}

@media (min-width: 605px) {
  .ipad-setting-menu {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .mobile-setting-menu {
    bottom: 10vh !important;
  }
}

@media (min-width:1023px) {
  .ipad-setting-menu {
    width: auto !important;
    position: relative !important;
    bottom: auto !important;
  }
}

@media (max-width: 1023px) {
  .ap-logo {
    width: 150px;
  }

  .lh-logo {
    width: 180px;
  }

  .hr-logo {
    width: 130px;
  }
}

@media (min-width: 1024px) {
  .ap-logo {
    width: 170px;
  }

  .lh-logo {
    width: 250px;
  }

  .hr-logo {
    width: 150px;
  }
}

.mobile-setting-menu--after-open {
  transform: translateY(0%);
}

.mobile-setting-menu--before-close {
  transform: translateY(100%);
}


.setting-menu-tab {
  font-family: "Optima nova";
  border: none;
  outline: none !important;
}

.cbe-bg-green {
  background: #183e3f;
}

.cbe-bg-green-lighter {
  background: #3c6668;
}

.cbe-bg-green-lightest {
  background: #d4e4e4;
}

.cbe-btn {
  background: #305253;
  color: #ffffff;
  padding-top: 8px;
  padding-bottom: 8px;
  /*letter-spacing: 0.1em;*/
  font-family: "Cormorant Garamond", Arial, sans-serif;
  font-style: italic;
  font-size: 20px;
}

.cbe-btn-svg-fill {
  filter: brightness(80%) sepia(100%) hue-rotate(30deg);
}

.cbe-btn-text-green {
  color: #305253;
}

.cbe-btn-text-font {
  font-family: "Optima nova", sans-serif;
}

.cbe-text-color-teal {
  color: #183e3f;
}

.cbe-dot-bg-override {
  background-color: unset !important;
}

.cbe-font {
  font-family: "Cormorant", serif !important;
  font-weight: 500 !important;
  letter-spacing: 0px !important;
  line-height: 1 !important;
}

.cbe-font-black {
  font-weight: 800;
}

.cbe-font-comorant {
  font-family: "Comorant", serif !important;
}

.cbe-font-comorant-garamond {
  font-family: "Cormorant Garamond", Arial, sans-serif;
}

.cbe-font-optima {
  font-family: "Optima nova", sans-serif !important;
}

.cbe-font-label {
  font-family: "Optima nova", sans-serif !important;
  letter-spacing: 0.025em !important;
  line-height: 1.4 !important;
}

.cbe-font-mono {
  /* font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; */
  font-family: "Optima nova", sans-serif !important;
  /* font-size: 2rem !important; */
  line-height: 2 !important;
}

.cbe-font-select-label {
  text-transform: uppercase !important;
  letter-spacing: 0.3em !important;
  font-size: 0.8em !important;
  font-weight: 400 !important;
  font-family: "Optima nova", sans-serif !important;
  color: #305253 !important;
}

.cbe-footer-text {
  font-family: "Optima nova", sans-serif !important;
  color: #305253 !important;
}

.cbe-message-placeholder-fix {
  position: unset !important;
}

.cbe-select {
  background-color: inherit !important;
  color: inherit !important;
  appearance: none !important;
  padding-right: 28px !important;
  padding-top: 8px !important;
  padding-left: 8px !important;
  padding-bottom: 4px !important;
  text-indent: 0.01px !important;
  border: 1px solid !important;
  border-color: #e8e8e1 !important;
  background-image: url(https://cdn.shopify.com/s/files/1/0719/6907/9593/t/2/assets/ico-select.svg) !important;
  background-repeat: no-repeat !important;
  background-position: right 10px center !important;
  background-size: 11px !important;
  max-width: 100% !important;
  border-radius: 0 !important;
  font-family: "Optima nova", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-text-size-adjust: 100% !important;
  letter-spacing: 0.025em !important;
  line-height: 1.4 !important;
}

.cbe-spacing {
  letter-spacing: 1.6rem;
}

.cbe-spinner {
  padding-top: 8px;
  padding-bottom: 8px;
  content: "";
  /* display: block; */
  width: 24px;
  height: 24px;
  /*position: absolute;*/
  /* left: 50%; */
  /* top: 50%; */
  /*margin-left: -12px;*/
  /* margin-top: -12px;*/
  border-radius: 50%;
  border: 3px solid;
  border-color: #fff;
  border-color: #305253;
  border-top-color: transparent;

  animation: spin 1s linear infinite;
}

.cbe-table {
  border-spacing: 1.25px !important;
  border-width: 1px !important;
  border-color: #305253 !important;
  font-family: "Optima nova", sans-serif !important;
}

.cbe-table-head {
  background-color: #305253 !important;
  color: #ffffff !important;
  font-weight: 700 !important;
  text-align: left !important;
  padding: 10px 15px !important;
  letter-spacing: 0.025em !important;
  line-height: 1.4 !important;
  font-family: "Cormorant Garamond", Arial, sans-serif;
  font-size: 1.2rem;
  border: 2px !important;
  border-color: #ffffff !important;
  border-radius: 0px !important;
}

.cbe-td {
  border-spacing: 1.25px !important;
  border-width: 1px !important;
  border-color: #305253 !important;
  border-color: #305253 !important;
  padding: 10px 15px !important;
  font-family: "Optima nova", sans-serif !important;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

.loaderContainer p {
  font-size: 50px;
  font-weight: 600;
  color: #000;
  letter-spacing: 7px;
}

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
}

.loaderBackdropContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #00000069;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 70px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@media screen and (max-width: 767px) {
  .lds-ellipsis div {
      width: 10px;
      height: 10px;
  }
  .lds-ellipsis {
      width: 70px;
      height: 70px;
  }
  .loaderContainer {
      width: 90%;
      max-width: 767px;
  }
  .loaderContainer p {
      font-size: 26px;
  }
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
