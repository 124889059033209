@import "../../../styles/index.scss";

.info-modal {
  &__overlay {
    background: transparent;
    padding: 0;
    transition: all 1000ms ease;

    &.entered {
      background: rgba($title-color, 0.25);
    }
  }

  &--open {
    > #root {
      filter: blur(5px);
      transition: all 1000ms ease;
    }
  }

  &__container {
    max-width: 600px;
    width: 100%;
    min-height: 300px;
    max-height: 550px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    padding: 20px;
    margin-bottom: 0;
    overflow: scroll;
    box-shadow: 0px -10px 30px 5px rgba($title-color, 0.25);
  }

  &--enter {
    transform: translateY(100%);
  }

  &--enter-active {
    transform: translateY(0);
    transition: transform 300ms ease;
  }

  &--exit {
    transform: translateY(0);
  }

  &--exit-active {
    transform: translateY(100%);
    transition: transform 300ms ease;
  }
}

.talk-to-us {
  width: 100%;
  text-align: right;

  &__title {
    text-align: center;
    color: $title-color;
    font-size: 22px;
    font-family: $font-cormorant;
    font-style: italic;
  }

  &__content {
    margin-top: 45px;
    text-align: left;
    font-family: $font-optima;
    font-size: 14px;
    color: $sub-title-color;

    &__title {
      color: $title-color;
      font-weight: bold;
      
      & > a {
        color: $title-color;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    &__desc {

      & > a {
        color: $sub-title-color;
      };

      &:last-child {
        margin-bottom: 40px;
      }
    }
  }

  &--close {
    border-color: transparent;
    background-color: transparent;
  
    &:hover {
      border-color: transparent;
      background-color: transparent;
      box-shadow: none;
      outline: none;
    }

    &:active {
      border-color: transparent !important;
      background-color: transparent !important;
      box-shadow: none !important;
      outline: none !important;
    }

    &:focus {
      border-color: transparent !important;
      background-color: transparent !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}
