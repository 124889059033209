.clickable-div {
  transition: all 300ms ease;

  &.disabled {
    opacity: 0.6;
    transition: all 300ms ease;

    &:hover {
      cursor: auto;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
